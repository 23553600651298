/*===================== 
	Premery bg color 4611a7  7133e2 
=====================*/
:root{
	--text-color: #006b8e;
	--bg-color: #006b8e;
	--bg-color-ho: #3e8fa9;
}
/* Hex */
.meet-ask-row:after,
.dlab-separator.style-skew[class*="style-"]:after,
.dlab-separator.style-skew[class*="style-"]:before,
.dlab-tilte-inner.skew-title:after,
.date-style-2 .post-date,
.date-style-3.skew-date .post-date,
.date-style-3.skew-date .post-date:before,
.widget-title:after,
.site-button,
.comments-area .comment-form p input[type="submit"],
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.footer-line:after,
.testimonial-1 .quote-left:before,
.testimonial-2 .quote-left:before,
.site-filters .active > [class*="site-button"],
.list-num-count > li:before,
.date-style-4 .post-date strong,
.date-style-3 .post-date,
.date-style-2 .post-date,
#myNavbar li.active a,
.header-style-4.style-1 .header-nav .nav > li.active a:before,
.header-style-4.style-1 .header-nav .nav > li:hover > a:before,
.dlab-project-left:after,
.testimonial-text.bg-primary,
.sidenav ul li a:hover,
.owl-theme.dots-style-2 .owl-dots .owl-dot.active span,
.owl-btn-1.primary .owl-prev, 
.owl-btn-1.primary .owl-next,
.owl-btn-2.primary .owl-prev, 
.owl-btn-2.primary .owl-next,
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active span, 
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover span,
.product-item-size .btn.active,
.ui-widget-header,
.owl-theme.dots-style-3 .owl-dots .owl-dot.active span, 
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover span,
.owl-item.active.center .testimonial-text,
.icon-bx-wraper.hover:hover,
.dlab-accordion.primary .acod-head a,
.side-bar .widget_tag_cloud a:hover,
.tabs-site-button .nav-tabs li a.active:focus,
.tabs-site-button .nav-tabs li a.active:hover,
.tabs-site-button .nav-tabs li a.active,
.pagination-bx .pagination li.active a,
.pagination-bx .pagination li a:hover,
.overlay-primary-light:after, 
.overlay-primary-middle:after, 
.overlay-primary-dark:after,
.overlay-primary:after,
.bg-primary,
.search-filter.filter-style1 .bootstrap-select div.dropdown-menu ul li a:hover,
.search-filter.filter-style1 .bootstrap-select div.dropdown-menu ul li.selected a,
.search-filter.filter-style3 .bootstrap-select div.dropdown-menu ul li a:hover,
.search-filter.filter-style3 .bootstrap-select div.dropdown-menu ul li.selected a,
.site-header.header-style1 .header-nav .nav > li.active:after,
.tag-new{
    background-color: #3e8fa9;
}
/*Hex color :hover */
.site-button:active,
.site-button:hover,
.site-button:focus,
.active > .site-button,
.bg-primary-dark,
.pagination > li > a:hover, 
.pagination > li > span:hover, 
.pagination > li > a:focus, 
.pagination > li > span:focus ,
.pagination > .active > a, 
.pagination > .active > span, 
.pagination > .active > a:hover, 
.pagination > .active > span:hover, 
.pagination > .active > a:focus, 
.pagination > .active > span:focus,
.sidenav .closebtn:hover,
.sidenav .closebtn:focus,
.owl-btn-1.primary .owl-prev:hover, 
.owl-btn-1.primary .owl-next:hover,
.owl-btn-2.primary .owl-prev:hover, 
.owl-btn-2.primary .owl-next:hover{
    background-color: #3e8fa9
;
}

/* Rgba color */
.dlab-info-has.bg-primary,
.dlab-info-has.bg-secondry:hover{
    background-color: rgba(108, 192, 0, 0.9);
}
/* Box Shadow */
.side-bar .widget_tag_cloud a:hover{
	box-shadow:5px 5px 10px 0 rgba(108, 192, 0, 0.15);
}
/*===================== 
	Premery text color 
=====================*/
a,
.text-primary,
.primary li:before,
.breadcrumb-row ul li a,
.manu-white .header-nav .nav > li.active > a,
.header-nav .nav > li.active > a,
.header-nav .nav > li.current-menu-item > a .header-nav .nav > li .sub-menu li a:hover,
.header-nav .nav > li:hover > a,
.nav-dark.header-nav .nav > li .sub-menu li:hover > a,
.nav-dark.header-nav .nav > li .mega-menu > li ul a:hover,
blockquote:before,
ol.comment-list li.comment .reply a,
footer a:active,
footer a:focus,
footer a:hover,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a,
button.scroltop,
.testimonial-1 .testimonial-position,
.testimonial-4 .testimonial-name:after,
.testimonial-2 .testimonial-position,
.testimonial-3 .testimonial-position,
.testimonial-3 .quote-left:before,
.acod-head a:after,
.acod-head a,
.acod-head a:hover,
.acod-head a.collapsed:hover,
.dlab-tabs .nav-tabs > li > a.active i,
.dlab-tabs .nav-tabs > li > a.active,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.site-button.outline,
.site-button-link:hover,
.item-info-in ul li a:hover,
.dlab-post-meta.text-primary i,
.dlab-post-meta.text-primary ul li,
.dlab-post-meta.text-primary ul li a,
.dlab-post-meta i,
.comments-area p:before,
.list-2 li:hover:before,
a.text-primary:focus, 
a.text-primary:hover,
.sr-box.style-1:hover .count,
.blog-post.style1 .dlab-post-meta .post-date {
    color: #006b8e;
}
@media only screen and (max-width: 991px) {
	.nav-dark.header-nav .nav > li > a:hover, 
	.nav-dark.header-nav .nav > li > a:active, 
	.nav-dark.header-nav .nav > li > a:focus{
		color: #006b8e;
	}
}
/*===================== 
	Premery border color 
=====================*/
.navbar-toggle,
.testimonial-4 .testimonial-pic,
.testimonial-4:after,
.testimonial-4 [class*="quote-"],
button.scroltop,
blockquote,
.widget_gallery li:hover,
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active, 
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
input[type="checkbox"]:checked + label:before,
input[type="checkbox"] + label:hover:before, 
input[type="radio"] + label:hover:before,
.owl-theme.dots-style-3 .owl-dots .owl-dot.active, 
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover,
.side-bar .widget_tag_cloud a:hover{
    border-color: #006b8e;
}
.owl-item.active.center .testimonial-8 .testimonial-text:after{
	border-top: 15px solid #006b8e;
}
.dlab-tabs.border-top .nav-tabs > li > a.active,
.header-nav .nav > li .sub-menu, 
.header-nav .nav > li .mega-menu{
	border-top-color: #006b8e;
}
.port-style1 .port-box{
	border-left: 3px solid #006b8e;
}
.choseus-tabs .nav li a.active{
	border-bottom: 2px solid #006b8e;
}
svg.radial-progress:nth-of-type(6n+1) circle { stroke: #006b8e; }
.modal-bx-info .dlab-form input[type="checkbox"]:checked + label:before{
	border: 2px solid #006b8e;
}


/*===================== 
	Secondery bg color
 =====================*/
.bg-secondry {
    background-color: #2d3239;
}

/*===================== 
	Secondery text color
 =====================*/
.text-secondry {
    color: #2d3239;
}

.modal-bx-info .modal-header,
.fc-toolbar.fc-header-toolbar,
.listing-timing-box .listing-timing-header,
.listing-details-nav,
.work-box-area .icon-box,
.category-bx.style1,
.filter-style2-area,
.filter-style3 form .form-group .title,
.work-box.style1:hover,
.work-box.style1 .box-count,
.site-filters.listing-filters ul li a:after,
.dlab-tabs.search-filter .nav-tabs > li > a.active, 
.dlab-tabs.search-filter .nav-tabs > li > a.active:focus, 
.dlab-tabs.search-filter .nav-tabs > li > a.active:hover, 
.dlab-tabs.search-filter .nav-tabs > li > a:focus, 
.dlab-tabs.search-filter .nav-tabs > li > a:hover{
    background-color: #006b8e;
}
.login-form .info-bottom a, 
.login-form-box .btn-link, 
.modal-bx-info .btn-link,
.average-reviews-single .average-reviews-info .average-reviews-in,
 .average-reviews-single .average-reviews-info span,
.average-reviews-info .average-reviews,
.sale-box .sale-date .title a,
.content-header .title i,
.listing-filters.text-white ul li.active a span,
.featured-bx.style3 .featured-info .title a,
.blog-post.style2 .dlab-post-meta ul li i,
.work-box.style1:hover .box-count,
.site-filters.listing-filters ul li.active a{
	color: #006b8e;
}
.icon-box-list .icon-cell.bg-gray, 
.icon-box-info .icon-cell.bg-gray{
	color: #006b8e !important;
}
.work-box.style1{
    border: 4px solid #006b8e;
}
.listing-nav li a.active,
.center .testimonial-2.testimonial-bg .testimonial-text:after{
    border-top-color: #006b8e;
}
.icon-box-list .icon-cell.bg-gray, 
.icon-box-info .icon-cell.bg-gray{
    background-color: #e9ddff;
}